<template>
  <v-card-text class="app-card-body">
    <slot></slot>
  </v-card-text>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>

<style lang="scss">
</style>
