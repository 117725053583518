<template>
  <div class="container">
    <v-card
      elevation="20"
      :loading="loading"
      outlined
    >
      <v-card-title class="">
        <p class="ml-3">
          صور العجلات التي تم السماح لها بالدخول مع :- {{ name }}
        </p>
      </v-card-title>
      <template>
        <v-row v-if="!loading" class="mx-2 mb-2">
          <vue-picture-swipe v-if="images.length" :items="images"></vue-picture-swipe>
          <v-alert v-if="!images.length"
                   type="error"
                   style="margin: 0 auto"
          >
            لا يوجد صور
          </v-alert>
        </v-row>
      </template>
      <template>
        <v-card-title class="">
          <p class="ml-3">
            تفاصيل العجلات
          </p>
        </v-card-title>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center font-weight-bold">
                  #
                </th>
                <th class="text-center font-weight-bold">
                  الرقم
                </th>
                <th class="text-center font-weight-bold">
                  النوع
                </th>
                <th class="text-center font-weight-bold">
                  العمليات
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(car,i) in cars" :key="car.id" class="text-center">
                <td>{{ i+1 }}</td>
                <td>{{ car.number }}</td>
                <td>{{ car.type }}</td>
                <td>
                  <v-btn
                    depressed
                    color="error"
                    small
                    @click="deleteFun(car.id)"
                  >
                    <v-icon dark>
                      mdi-delete
                    </v-icon>
                    حذف
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>

      <v-spacer></v-spacer>
      <template class="container mt-3">
        <v-card>
          <v-card-title>
            اضافة عجلة جديدة
          </v-card-title>
          <app-card-body>
            <v-form ref="form"
                    v-model="valid"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="form.number"
                      v-max-length="200"
                      outlined
                      type="text"
                      :rules="numberRule"
                      label="الرقم"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="form.type"
                      v-max-length="200"
                      outlined
                      :rules="typeRule"
                      label="النوع"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-can="'update_cars'"
                    :disabled="!valid || loadingSubmit"
                    :loading="loadingSubmit"
                    color="success"
                    class="mr-4 mt-2"
                    @click="submit()"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-plus
                    </v-icon>
                    اضف
                  </v-btn>
                  <v-btn
                    color="info"
                    class="mr-4 mt-2"
                    @click="back()"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-arrow-right
                    </v-icon>
                    الرجوع الى الاحصائيات
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </app-card-body>
        </v-card>
      </template>
    </v-card>
  </div>
</template>

<script>
import axios from '@axios'
import VuePictureSwipe from 'vue-picture-swipe'
import AppCardBody from '@core/components/app-card/AppCardBody'
import toast from '@/store/toast'

export default {
  name: 'Checkpoints',
  components: { AppCardBody, VuePictureSwipe },
  data: () => ({
    translationKey: 'pages.Identifiers',
    filters: false,
    loading: true,
    loadingSubmit: false,
    name: '',
    form: {
      number: '',
      type: '',
    },
    valid: false,
    customEntries: [],
    images: [
    ],
    seq: 1,
    cars: [],
    options: {},
    type: 'dignitary',
    search: '',
    numberRule: [
      v => !!v || 'الرقم مطلوب',
    ],
    typeRule: [
      v => !!v || 'النوع مطلوب',
    ],

  }),
  setup() {
    return {

    }
  },
  async mounted() {
    await this.fetch();
  },

  methods: {
    async fetch(images = true) {
      try {
        const response = await axios.get(`/customEntries/${this.$route.params.id}`)
        if (images) {
          this.images = response.data.data.media.map(media => ({
            src: media.original_url,
            thumbnail: media.preview_url || media.original_url,
            h: 1000,
            w: 1000,
          }));
        }
        this.name = response.data.data.name;
        this.cars = response.data.data.cars;
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      try {
        this.loadingSubmit = true;
        if (this.$refs.form.validate()) {
          await axios.post(`customEntries/add_car/${this.$route.params.id}`, this.form)
          await toast.dispatch('success', this.$t('form.success.update'))
          await this.fetch(false);
          this.$refs.form.reset();
        }
      } finally {
        this.loadingSubmit = false;
      }
    },
    async deleteFun(id) {
      await axios.delete(`customEntries/remove_car/${this.$route.params.id}/${id}`, this.form)
      await toast.dispatch('success', this.$t('form.success.delete'))
      await this.fetch(false);
    },
    back() {
      this.$router.go(-1)
    },
    $(key) {
      return this.$t(`${this.translationKey}.${key}`);
    },
    showImage(i) {
      this.index = i;
    },
  },

}
</script>
<style>
[itemprop="thumbnail"] {
  width: 200px;
  height: 300px;
}
</style>
